"use client";

import { useEffect } from "react";
import {
  getUserInfo,
  getUserSession,
  userAgreementStatus,
} from "@/actions/user";
import useUserStore from "@/stores/useUserStore";

export const useFetchUserInformation = (userId: number) => {
  const { setAgreementStatus, setUserInfo, userInfo, userHasVerified } =
    useUserStore();

  useEffect(() => {
    if (!userId) {
      return;
    }

    (async () => {
      const data = await userAgreementStatus();
      setAgreementStatus(data);
      //const sessionInfo = await getUserSession();

      const randomString = Math.random().toString(36).substring(7);
      const userInfo = await getUserInfo(randomString);

      if (!userInfo) {
        return;
      }

      setUserInfo({
        ...userInfo,
        //refUserName: sessionInfo.refUserName,
      });
    })();
  }, [userId, userHasVerified]);
};
