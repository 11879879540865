"use client";

import { parseDate, parseToDate } from "@/lib/utils";
import useServerTimeStore from "@/stores/useServerTimeStore";
import { useEffect } from "react";

const REFRESH_TIMER_MS = 30000; // 30 seconds

const ServerTimer = () => {
  const { setServerTime, initialServerTime } = useServerTimeStore();

  const fetchServerTime = async () => {
    try {
      const startTime = performance.now();

      const data = await fetch(`${window.location.origin}/api/server-time`, {
        headers: { "X-Requested-With": "fetch" },
      });

      const response = await data.json();
      const serverTime = response?.serverTime || null;

      const endTime = performance.now();
      const requestDurationMs = endTime - startTime;

      if (serverTime) {
        const serverTimeMs = parseDate(serverTime);

        const durationSeconds = Math.ceil(requestDurationMs);

        const serverDate = parseToDate(serverTimeMs + durationSeconds);
        const correctedTime = serverDate.toISOString().replace("Z", "+00:00");

        setServerTime(correctedTime);
      } else {
        setServerTime(null);
        //console.error("Failed to fetch server time:", response?.error);
      }
    } catch (error) {
      setServerTime(null);
      //console.error("Failed to fetch server time:", error);
    }
  };

  useEffect(() => {
    fetchServerTime();

    console.log("Init fetch server time");
    const interval = setInterval(fetchServerTime, REFRESH_TIMER_MS);

    window.addEventListener("load", fetchServerTime);
    window.addEventListener("online", fetchServerTime);
    window.addEventListener("offline", fetchServerTime);

    return () => {
      clearInterval(interval);
      window.removeEventListener("load", fetchServerTime);
      window.removeEventListener("online", fetchServerTime);
      window.removeEventListener("offline", fetchServerTime);
    };
  }, []);

  return null;
};

export default ServerTimer;
