import classNames from "classnames";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";

interface ModalContent {
  modalTitle?: string;
  modalBody: string | ReactElement;
  modalFooter?: string | ReactElement;
  buttons?: ReactElement[];
  modalBg?: string;
  id?: string;
  onClose: () => void;
  useFloatingHeader?: boolean;
  size?: "md" | "lg" | "xl";
  sticky?: boolean;
  modalTitleClass?: string;
  isOpen?: boolean;
  wrapperExtraClass?: string;
  modalBackDropBackdropClass?: string;
  outerClassNames?: string;
}

const ModalElement: React.FC<ModalContent> = React.memo(
  ({
    modalTitle,
    modalBody,
    sticky = false,
    modalBg = "bg-gray-ultra-light",
    wrapperExtraClass = "",
    modalFooter = null,
    onClose,
    size = "md",
    isOpen = false,
    outerClassNames = false,
    id,
    modalBackDropBackdropClass = false,
    modalTitleClass = "",
  }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const wrapperClass = classNames(
      "relative md:my-6 md:mx-6 max-w-3xl border-0 rounded-lg shadow-lg w-full h-fit",
      {
        [`${modalBg}`]: true,
        "md:!w-[382px] !md:max-w-[382px] px-6 py-8": size === "md",
        "md:!w-[514px] md:!max-w-[514px] px-6 py-8 md:p-10": size === "lg",
        "lg:min-w-[628px] px-6 py-8 md:p-10": size === "xl",
        [`${wrapperExtraClass}`]: wrapperExtraClass,
      }
    );

    const headerClass = classNames(
      "flex items-start flex-col justify-between rounded-t"
    );

    const modalBackDrop = classNames({
      "fixed inset-0 z-[990] bg-black/50 backdrop-blur-sm":
        !modalBackDropBackdropClass,
      [`${modalBackDropBackdropClass}`]: modalBackDropBackdropClass,
    });

    // Prevent scrolling when modal is open
    useEffect(() => {
      document.body.style.overflow = isOpen ? "hidden" : "auto";
    }, [isOpen]);

    useEffect(() => {
      if (sticky) return;

      const checkIfClickedOutside = (e: MouseEvent) => {
        if (
          !sticky &&
          containerRef.current &&
          !containerRef.current.contains(e.target as Node)
        ) {
          onClose();
        }
      };
      document.addEventListener("click", checkIfClickedOutside, true);

      return () => {
        document.removeEventListener("click", checkIfClickedOutside, true);
      };
    }, [onClose]);

    const outerClass = classNames(
      "justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none m-5",
      {
        [`${outerClassNames}`]: outerClassNames,
      }
    );

    if (!isOpen) return null;

    return (
      <>
        <div id={id} className={outerClass}>
          <div className={wrapperClass} ref={containerRef}>
            {!sticky && (
              <button
                className="absolute top-0 right-0 p-2 m-3 md:-m-3 border-0 cursor-pointer text-3xl leading-none font-semibold outline-none focus:outline-none bg-blue rounded-full text-white text-12 z-[900]"
                onClick={onClose}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            )}

            {/*content*/}
            <div className="relative flex flex-col w-full outline-none focus:outline-none">
              {/*header*/}
              <div className={headerClass}>
                {modalTitle && (
                  <h3
                    className={classNames(
                      "text-2xl font-semibold text-modal-blue mt-6 mb-8 md:mb-12",
                      { [`${modalTitleClass}`]: modalTitleClass }
                    )}
                  >
                    {modalTitle}
                  </h3>
                )}
              </div>
              {/*body*/}
              <div className="relative flex-auto">
                <div className="text-blueGray-500 text-lg leading-relaxed">
                  {modalBody}
                </div>
              </div>
              {/*footer*/}
              <div className="flex w-full">{modalFooter}</div>
            </div>
          </div>
        </div>
        <div className={modalBackDrop} onClick={onClose}></div>
      </>
    );
  }
);

ModalElement.displayName = "ModalElement";

export default React.memo(ModalElement);
