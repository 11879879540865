"use client";

import useUserStore from "@/stores/useUserStore";
import React, { useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import useLoginClass from "@/hooks/useLoginClass";
import { useFetchUserFavorites } from "@/hooks/useFetchUserFavorites";
import { useFetchUserInformation } from "@/hooks/useFetchUserInformation";
import { useFetchUserAccountMessages } from "@/hooks/useFetchUserAccountMessages";

interface ClientOnlyProps {
  children: any;
}

const ClientOnly: React.FunctionComponent<ClientOnlyProps> = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const { user } = useUserStore(useShallow((state) => state));

  const userId = useMemo(() => user?.id, [user?.id]);

  useLoginClass(userId);
  //useNotificationManagement(userId);
  useFetchUserAccountMessages(userId);
  useFetchUserFavorites(userId);
  useFetchUserInformation(userId);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return children;
};

export default ClientOnly;
