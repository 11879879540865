import { useEffect } from "react";
import { validateUserSession } from "@/actions/user";
import useUserStore from "@/stores/useUserStore";
import { signOut } from "next-auth/react";

const useValidateSession = () => {
    const { clearSession, setUser, user } = useUserStore();

    const validate = async () => {
        fetch(process.env.NEXT_PUBLIC_SITE_URL + '/api/session?do=verify', {
            headers: { "X-Requested-With": "fetch" },
        }).then((res) => res.json()).then(async (data) => {
            const session = data?.session;

            if (!session || session?.tokenStatus === "none") {
                clearSession();
                return;
            }

            if (session?.tokenStatus === "invalid") {
                clearSession();
                await signOut({ redirect: false });
                return;
            }

            setUser({
                accessToken: session.accessToken,
                user: {
                    id: session?.userId || 0,
                }
            });
        }).catch(() => {
            clearSession();
        })
    };

    return {
        validate
    }
};

export default useValidateSession;