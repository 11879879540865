"use client";

import { Tooltip } from "react-tooltip";

const TooltipWrapper = () => {
  return (
    <>
      <Tooltip
        id="default-tooltip"
        className="z-[900] !bg-white !text-paragraph max-w-xl !opacity-100 !shadow-lg"
        positionStrategy="fixed"
      />
      <Tooltip
        id="light-tooltip"
        className="z-[900] !bg-aliceblue-dark !text-blue-dark max-w-xl !rounded-lg !opacity-100 !shadow-md shadow-md"
        place="bottom-end"
      />
    </>
  );
};

export default TooltipWrapper;
