"use client";

import React, { useEffect, useState } from "react";
import useModalStore from "@/hooks/useModalStore";
import Modal from "@/components/Modal/Modal";
import { Authentication } from "../Authentication/Wrapper";
import ModalElement from "@/components/Modal/Modal";

const LoginModal: React.FC = () => {
  const { loginModal } = useModalStore();

  const BodyContent = (
    <>
      <Authentication
        initialProps={{
          show: "login",
          from: "modal",
          isBarVisible: true,
          selectedLink: "email",
          hideTitles: true,
          isLoginModal: true,
        }}
        from="login"
      />
    </>
  );

  return (
    <ModalElement
      isOpen={loginModal.isOpen}
      onClose={loginModal.onClose}
      modalTitle={loginModal.title || "Logga in för att lägga bud"}
      modalBody={BodyContent}
      size="lg"
    />
  );
};

export default LoginModal;
