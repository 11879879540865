import classNames from "classnames";
import React, { MouseEventHandler } from "react";

interface GoBackProps {
  onClick?: MouseEventHandler;
  text: string;
  direction?: "left" | "right";
  extraClasses?: string;
}

const ArrowButton: React.FC<GoBackProps> = ({
  onClick,
  text,
  direction = "right",
  extraClasses = "",
}) => {
  const arrowClass = classNames("transition-all duration-500", {
    "inline-block rotate-180 mx-4 group-hover:-translate-x-[3px] !mr-4":
      direction === "left",
    "group-hover:translate-x-[3px]": direction === "right",
  });

  const flexClass = classNames("flex space-x-3", {
    [extraClasses]: extraClasses,
    "flex-row-reverse": direction === "left",
    "flex-row": direction === "right",
  });

  return (
    <span
      onClick={onClick ? onClick : undefined} // Only set onClick if it exists
      className="group block w-fit text-blue font-semibold cursor-pointer"
    >
      <div className={flexClass}>
        <span>{text}</span>
        <span className={arrowClass}>➜</span>
      </div>
    </span>
  );
};

export default ArrowButton;
