"use client";

const REFRESH_NOTIFICATION_TIME = 3600 * 1000; // 1 hour

import useSystemMessagesStore from "@/stores/useAccountMessagesStore";
import useSWR from "swr";
import { useEffect } from "react";
import { fetchAccountMessages, getUserAuctionItemFavoritesIds } from "@/actions/user";
import { MD5 } from "crypto-js";
import useUserStore from "@/stores/useUserStore";
import { FETCH_AUCTION_FAVORITE_TIME } from "@/lib/auctions";
import useUserFavoritesStore from "@/stores/useUserFavoritesStore";

export const useFetchUserFavorites = (userId?: number) => {
    const { setFetchKeys, fetchKeys } = useUserStore();
    const { setUserFavorites } = useUserFavoritesStore();

    const favoriteFetchKey = fetchKeys?.favorites || MD5(`userFavorites-${userId}`).toString();

    useSWR<any, Error>(
        userId ? favoriteFetchKey : null,
        async () => {
            const favorites = await getUserAuctionItemFavoritesIds(favoriteFetchKey);
            if (!favorites) return [];

            setUserFavorites(favorites);

            return favorites
        }
        ,
        {
            refreshInterval: FETCH_AUCTION_FAVORITE_TIME
        }
    );

};
