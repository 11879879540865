"use client";

import { useEffect, useState } from "react";
import useValidateSession from "@/hooks/useValidateSession"; // Your existing custom hook

const VALIDATE_SESSION_TIME = 60000 * 5; // 5 minutes

const UserValidation = () => {
  const { validate } = useValidateSession();

  const handleValidationTrigger = () => {
    //console.log("Triggered validation, running ...");
    validate();
  };

  // Also check each 5 minutes if the user is still valid
  useEffect(() => {
    const interval = setInterval(() => {
      handleValidationTrigger();
    }, VALIDATE_SESSION_TIME);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.addEventListener("load", handleValidationTrigger);
    window.addEventListener("online", handleValidationTrigger);
    window.addEventListener("offline", handleValidationTrigger);

    // Run once on load
    handleValidationTrigger();

    // Cleanup the event listeners
    return () => {
      window.removeEventListener("load", handleValidationTrigger);
      window.removeEventListener("online", handleValidationTrigger);
      window.removeEventListener("offline", handleValidationTrigger);
    };
  }, []);

  return null;
};

export default UserValidation;
