import React, { useEffect, useState } from 'react'

export const useLoginClass = (userId: number) => {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        if (!hasMounted) return;

        const body = document.body;

        // Remove both classes to prevent duplication
        body.classList.remove("is-logged-in", "is-logged-out");

        if (userId) {
            body.classList.add("is-logged-in");
        } else {
            body.classList.add("is-logged-out");
        }

        // Cleanup when the component unmounts
        return () => {
            body.classList.remove("is-logged-in", "is-logged-out");
        };
    }, [userId, hasMounted]);

}

export default useLoginClass