import(/* webpackMode: "eager" */ "/vercel/path0/nextjs/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/nextjs/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/nextjs/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Archivo\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"variable\":\"--body-font\",\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"open_sans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/nextjs/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/nextjs/src/app/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/nextjs/shared/theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/nextjs/src/components/ClientOnly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/nextjs/src/components/Modal/LoginModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/nextjs/src/components/NetworkDetection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/nextjs/src/components/ServerTimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/nextjs/src/components/TooltipWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/nextjs/src/components/UserValidation.tsx");
