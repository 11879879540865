"use client";

const REFRESH_NOTIFICATION_TIME = 3600 * 1000; // 1 hour

import useSystemMessagesStore from "@/stores/useAccountMessagesStore";
import useSWR from "swr";
import { useEffect } from "react";
import { fetchAccountMessages } from "@/actions/user";

export const useFetchUserAccountMessages = (userId?: number) => {
  const { accountMessages, setAccountMessages, clearAccountMessages } =
    useSystemMessagesStore();

  useEffect(() => {
    if (!userId) {
      clearAccountMessages();
    }
  }, [userId]);

  const { error, isLoading } = useSWR<any, Error>(
    userId ? ["api/fetchUserSystemMessages", userId] : null,
    async () => {
      if (!userId) {
        clearAccountMessages();
        return;
      }

      const data = await fetchAccountMessages();
      setAccountMessages(data);
    },
    {
      refreshInterval: REFRESH_NOTIFICATION_TIME,
    }
  );
};
