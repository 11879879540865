"use client";

import Button from "@/components/Elements/Button";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "./Modal/Modal";
import ModalElement from "@/components/Modal/Modal";
import useServerTimeStore from "@/stores/useServerTimeStore";

const connectionMessage = (
  <div>
    <p>
      För att du ska kunna följa budläggningen på ett rop så måste din
      webbläsare ha koppling till servern hela tiden. Om det inte finns så är
      det inte säkert att du ser den senaste informationen.
    </p>
    <p>
      Om du får en varning om att &quot;kopplingen till servern verkar ha
      förlorats&quot; så kan det bero på flera orsaker. Nedanstående åtgärder
      löser de flesta problem. Gör de i ordningen uppifrån och ner:
    </p>
    <ol>
      <li>
        <strong>Ladda om sidan</strong>
        &nbsp;- Detta löser de flesta problem
      </li>
      <li>
        <strong>Stäng alla utom en flik med tovek.se</strong> - Om det finns
        många flikar från tovek.se öppna kan alla anslutningar bli upptagna till
        din dator.
      </li>
      <li>
        <strong>Starta om webbläsaren</strong>&nbsp;- Stäng ner webbläsaren helt
        och hållet och starta den igen.
      </li>
      <li>
        <strong>Prova i en annan webbläsare</strong> - En vanlig webbläsare som
        fungerar på alla enheter är{" "}
        <a
          href="https://www.google.com/aclk?sa=l&amp;ai=DChcSEwiz2drksPTnAhWCtRgKHdG_ASsYABABGgJsZQ&amp;sig=AOD64_0QqaRfIiXRSeVtbWF-il3QMRSwMQ&amp;adurl=&amp;q=&amp;ved=2ahUKEwiY_tPksPTnAhXvwqYKHcd1AFAQqyQoAHoECBMQBw"
          target="_blank"
          rel="noopener"
        >
          Chrome
        </a>
      </li>
    </ol>
  </div>
);

const NetworkDetection = () => {
  const [isOnline, setOnline] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { initialServerTime } = useServerTimeStore();

  const updateNetworkStatus = () => {
    setOnline(navigator.onLine);
  };

  const showNetworkToast = () => {
    toast(
      <div className="flex flex-col gap-4 text-center md:text-left md:flex-row items-center space-x-4 text-base md:text-lg">
        <span>Kopplingen till servern verkar ha förlorats.</span>

        <div className="div flex flex-row items-center space-x-2">
          <Button
            bgColor="white"
            textColor="black"
            borderColor="white"
            onClick={() => window.location.reload()}
          >
            Ladda om sidan
          </Button>
          <Button
            bgColor="transparent"
            textColor="white"
            borderColor="white"
            onClick={() => setModalOpen(true)}
          >
            Läs mer
          </Button>
        </div>
      </div>,
      {
        style: {
          background: "#333",
          color: "#fff",
          padding: "16px",
          bottom: "0px",
          border: "none",
          width: "100%",
        },
        id: "network-status-toast",
        duration: Infinity,
        position: "bottom-center",
      }
    );
  };

  // For general latency, check server time each 10 seconds
  useEffect(() => {
    const timeout = setInterval(() => {
      if (initialServerTime) {
        toast.dismiss("network-status-toast");
        return;
      }

      showNetworkToast();
    }, 10000);

    return () => clearTimeout(timeout);
  }, [initialServerTime]);

  useEffect(() => {
    if (isOnline) {
      toast.dismiss("network-status-toast");
      return;
    }

    showNetworkToast();
  }, [isOnline]);

  // For server time
  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  return (
    <ModalElement
      isOpen={isModalOpen}
      onClose={() => setModalOpen(false)}
      modalTitle="Om kopplingen till servern"
      modalBody={connectionMessage}
      outerClassNames="!z-[99999]"
      size="lg"
    />
  );
};

export default NetworkDetection;
